﻿/*.table-scroll .ui-table.ui-widget,
.table-scroll .ui-table-scrollable-wrapper,
.table-scroll .ui-table-scrollable-view {
	height: 100%;
}

.table-scroll .ui-table-scrollable-body {
	height: calc(100% - 29px) !important;
	max-height: none !important;
}*/

.ui-table .ui-table-frozen-view .ui-table-tbody > tr > td,
.ui-table .ui-table-unfrozen-view .ui-table-tbody > tr > td,
.ui-table .ui-table-frozen-view .ui-table-thead > tr > th,
.ui-table .ui-table-unfrozen-view .ui-table-thead > tr > th {
  height: 34px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui-table-frozen-view .ui-table-thead > tr > th {
  background: #fff;
}

.ui-table-scrollable-footer {
  border-top: none !important;
}

.ui-table-frozen-view
  > .ui-table-scrollable-body
  > table
  > .ui-table-tbody
  > tr
  > td:last-child {
  &.group-end {
    border-right: solid 5px #fff;
  }
}

rdo-column-selector,
rdo-column-selector-product-type {
  .dropdown-menu {
    max-height: 50vh;
    //max-height: 315;
    overflow: auto;
  }
}

.ui-table-scrollable-wrapper {
  //margin-bottom: 18px;
  padding-bottom: 13px;
}

.ui-table-missing-scrollable-header {
  width: 1.2vw;
  height: 105px;
  width: 1.25vw;
  background: #f1f1f1;
  position: absolute;
  right: 15.3px;
  z-index: 1;
}

.ui-table-scrollable-view.ui-table-unfrozen-view {
  .ui-table-scrollable-header-box {
    margin-right: 0px !important;
  }

  div.ui-table-scrollable-body {
    overflow-y: auto !important;
    overflow-x: auto !important;
    border-bottom: none !important;
  }
}

.ui-table-scrollable-header-table {
  th {
    a {
      padding-top: 4px;
    }
  }
}

.ui-widget-header {
  border-bottom: none !important;
}

.ui-table-scrollable-header.ui-widget-header {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  background: #fff;
}

.rdo-ui-table.ui-table {
  display: block;
  color: #333;

  // z-index: -1;
  // .ui-table-scrollable-wrapper {
  // 	z-index: -1;
  // }
  .pi-sort-alt {
    display: none;

    &:before {
      content: '';
    }
  }

  .pi-sort {
    display: none;

    &:before {
      content: '';
    }
  }

  tr {
    height: 34px;
  }

  a:hover {
    text-decoration: none;
  }

  .ui-table-thead {
    tr {
      border: none !important;

      th {
        border: none;
        background-color: #fff;

        div {
          width: calc(100% - 13px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline-block;
          vertical-align: middle;
          max-height: 3em;
          line-height: 1.5em;
        }

        &.group-end {
          border-right: solid 5px #fff;
        }
      }
    }

    tr:not(:last-child) {
      th {
        position: relative;

        &:not(:first-child) &:not(.key-column),
        &.expandable {
          color: #fff;
          background: #99a8bd;
          text-align: center;

          a {
            color: #fff;
            position: absolute;
            top: 1px;
            right: 3px;
          }

          div {
            white-space: normal;
            line-height: 1.3em;
            max-height: 2.3em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    tr:last-child {
      th {
        font-size: 10px;
        background: #fff;
      }
    }

    tr:nth-child(2) {
      th {
        text-align: right;
        border-bottom: 2px solid #ddd;
      }
    }

    .ui-sortable-column {
      outline: none;

      &.ui-state-highlight {
        background-color: #245a8c;
        color: #fff;
        font-weight: bold;
        border-right: none;

        /*
				Close button within the column header needs to be white when the column is highlighted.
				The first is for the equipment table that has
				*/

        & .table-close-button {
          color: #fff;
        }
      }

      &:not(.group-end) {
        &:not(.ui-state-highlight) {
          &:hover {
            background-color: #fff;
            border-right: none;
          }
        }
      }

      &.group-end {
        &:not(.ui-state-highlight) {
          &:hover {
            background-color: #fff;
            border-right: solid 5px #fff;
          }
        }

        &.ui-state-highlight {
          border-right: solid 5px #fff;
        }
      }
    }
  }

  .ui-table-caption {
    text-align: right;
    position: fixed;
    right: 17px;
    top: 142px;
    z-index: 10000;
    /*z-index: 1000;
	    position: relative;*/
  }

  .ui-table-tbody {
    tr {
      &.table-expanded-row-first {
        background-color: #d0d0d0;
      }

      td {
        border: none;
        /*border-top: 1px solid #ddd;*/
        text-align: right;

        &.group-end {
          border-right: solid 5px #fff;
        }

        &.text-danger {
          color: #eb6767;
        }
      }
    }

    tr.frozen-row {
      background-color: #d9d9d9;
      /*color:#222222;*/
      font-weight: 700;
      text-transform: uppercase;
    }

    tr:nth-child(even) {
      /*background: #F0F8FA;*/
      background: #f5f5f5;
    }

    td.transparent-text {
      opacity: 0;
      filter: Alpha(opacity=0);
      /* IE8 and earlier */
    }
  }

  .ui-table-caption {
    background: transparent;
    border: none;
    padding: 0 0px 5px 0;
  }

  .ui-paginator {
    text-align: left;
  }

  .ui-paginator-bottom {
    //padding: 2rem 0;
    border: none;
    background: transparent;
  }
}

.ui-table.ui-table-hoverable-rows
  .ui-table-tbody
  > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: default !important;
}

.row-hover {
  cursor: default;
  background-color: #eaeaea !important;
  color: #212121 !important;
}

.rdo-paginator {
  &.ui-paginator {
    font-size: larger;
    text-align: left;
    //padding-top: 14px 0;
    border: none;
    background: transparent;
    margin-top: 14px;
  }
}

.rdo-head-paginator {
  position: fixed;
  top: 177px;
  right: 33px;
}

.rdo-narrow-title {
  width: 110px !important;
}

.rdo-wide-title {
  width: 171px !important;
}

.rdo-table-title {
  text-overflow: clip !important;
  white-space: normal !important;

  div {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.6em;
    height: 2.8em !important;
  }
}

.ui-paginator .ui-paginator-page,
.ui-paginator .ui-paginator-pages,
.ui-paginator .ui-paginator-next,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-prev,
.ui-paginator .ui-paginator-current {
  width: auto !important;
  min-width: 1.5em;
}

.ellipsed-th {
  .ellipsed-div {
    justify-content: flex-end;
    display: flex !important;
    height: 100%;
    width: 100% !important;

    div {
      width: 100% !important;
      white-space: normal !important;
    }

    p-sortIcon {
      padding-top: 3px;
    }
  }
}

#rdo-downloads-grid {
  font-family: Roboto, 'Trebuchet MS', Arial, Helvetica, sans-serif;
  font-size: 1em;

  .rdo-ui-table.ui-table {
    display: block;
    color: #222;
    text-size-adjust: 100%;
  }

  .ui-state-default {
    border: 1px solid #c3c4c7;
    background-color: #c3c4c7;
    color: #555555;
  }

  .ui-corner-all {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  a:focus {
    outline-width: 0px !important;
  }

  .ui-state-default a {
    color: #555555;
  }

  .ui-paginator a {
    color: #8385a7;
    font-weight: 500;
  }

  .ui-state-active {
    border: 1px solid #156090 !important;
    background: #186ba0;
    color: #ffffff !important;
  }

  .ui-state-active:hover {
    border: 1px solid #156090 !important;
    background: #186ba0;
  }

  .ui-inputtext {
    background: #ffffff;
    color: #222222;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
  }

  .ui-dropdown-trigger {
    background-color: #ffffff !important;
  }

  .ui-dropdown-panel {
    opacity: 1 !important;
    z-index: 10000 !important;
    background-color: #ffffff;
  }
}

#rdo-grid {
  .ui-corner-all {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  .ui-dropdown-panel .ui-dropdown-list {
    background-color: white;
  }

  .ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
    border-color: #c0c0c0;
    background-color: #eeeeee;
    color: #212121;
  }

  .ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #212121;
  }

  .ui-paginator a {
    color: #555555;
  }

  /* Forms */
  .ui-inputtext {
    background-color: #ffffff;
    color: #222222;
  }

  .ui-inputtext:enabled:hover {
    border-color: #c0c0c0;
  }

  .ui-inputtext.ui-state-focus,
  .ui-inputtext:focus {
    outline: 0 none;
    border-color: #c0c0c0;
    -moz-box-shadow: 0px 0px 5px #c0c0c0;
    -webkit-box-shadow: 0px 0px 5px #c0c0c0;
    box-shadow: 0px 0px 5px #c0c0c0;
  }

  .ui-state-default {
    border: 1px solid #d6d6d6;
    background-color: #ffffff;
    color: #555555;
  }

  .ui-state-default a {
    color: #555555;
  }

  .ui-state-active {
    border-color: #bebebe;
    background-color: #d6d6d6;
    color: #212121;
  }

  .ui-state-active a {
    color: #212121;
  }

  .ui-state-active {
    border: 1px solid #156090;
    background: #186ba0;
    color: #ffffff !important;
  }

  .ui-corner-top {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  .ui-corner-bottom {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .ui-corner-right {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .ui-corner-left {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
